<template>
  <div class="flex gap-x-2">
    <img
      :src="getFlag(countryCode)"
      class="h-3.5 w-3.5 rounded-sm object-fill"
    />
    <span class="truncate text-xs text-gray-500">
      {{ countries?.[countryCode] }}
    </span>
  </div>
</template>

<script setup lang="ts">
defineProps<{ countryCode: string }>()

const { data: countries } = useCountries()
</script>
